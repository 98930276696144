<template>
    <div>
        <div
            v-if="!loading"
        >
            <div
                v-if="!dataFound"
            >
                <b-alert
                    show
                    variant="info"
                >
                    <h4
                        class="alert-heading"
                    >
                        Whoops!
                    </h4>
                    <div
                        class="alert-body"
                    >
                        Sorry, but we didn't find any data that we could process for this dashboard!<br>
                        Most likely the reason is that you didn't have any orders accepted and processed.
                    </div>
                </b-alert>
            </div>
            <div
                v-else
            >
                <b-card no-body>
                    <b-card-header>
                        <div>
                            <b-card-title class="mb-1">
                                General
                            </b-card-title>
                            <b-card-sub-title>
                                Line chart for monthly order quantity from all Mirakl integrations, where cancelled and refunded
                                are not included in the list.
                            </b-card-sub-title>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <chartjs-line-chart
                            :height="400"
                            :plugins="plugins"
                            :data="tableData"
                        />
                    </b-card-body>
                </b-card>
                <b-row>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <b-card
                            no-body
                        >
                            <b-card-header>
                                <b-card-title>Percentage per accepted and shipped orders</b-card-title>
                                <b-dropdown
                                    text="Last 30 Days"
                                    variant="transparent"
                                    class="chart-dropdown "
                                    right
                                    no-caret
                                    toggle-class="p-0"
                                    size="sm"
                                >
                                    <b-dropdown-item>
                                        Last 30 days
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-card-header>
                            <b-card-body>
                                <vue-apex-charts
                                    type="radialBar"
                                    height="325"
                                    :options="productOrdersRadialBar.chartOptions"
                                    :series="productOrdersRadialBar.series"
                                />
                                <div
                                    class="d-flex justify-content-between mb-1"
                                >
                                    <div class="series-info d-flex align-items-center">
                                        <feather-icon
                                            icon="CircleIcon"
                                            size="16"
                                            class="text-info"
                                        />
                                        <span class="font-weight-bold text-capitalize ml-75">Accepted orders</span>
                                    </div>

                                    <span>{{ ordersAccepted.length }}</span>
                                </div>
                                <div
                                    class="d-flex justify-content-between mb-1"
                                >
                                    <div class="series-info d-flex align-items-center">
                                        <feather-icon
                                            icon="CircleIcon"
                                            size="16"
                                            class="text-success"
                                        />
                                        <span class="font-weight-bold text-capitalize ml-75">Shipped orders</span>
                                    </div>

                                    <span>{{ ordersShipped.length }}</span>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col
                        md="8"
                        sm="6"
                    >
                        <b-card no-body>
                            <b-card-header
                                class="align-items-baseline"
                            >
                                <div>
                                    <b-card-title
                                        class="mb-25"
                                    >
                                        Product synchronization
                                    </b-card-title>
                                    <b-card-text
                                        class="mb-0"
                                    >
                                        {{ (new Date()).getFullYear() }} | Monthly chart of product synchronization
                                    </b-card-text>
                                </div>
                                <feather-icon
                                    icon="SettingsIcon"
                                    size="18"
                                    class="text-muted cursor-pointer"
                                />
                            </b-card-header>
                            <b-card-body
                                class="pb-0"
                            >
                                <vue-apex-charts
                                    type="line"
                                    height="400"
                                    :options="salesLine.chartOptions"
                                    :series="salesLine.series"
                                />
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="TruckIcon"
                            color="success"
                            :statistic="mostCommonCarrier"
                            statistic-title="Most used courier"
                        />
                    </b-col>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="MapIcon"
                            color="info"
                            :statistic="mostCommonCountry"
                            statistic-title="Most ordered from a country"
                        />
                    </b-col>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="DollarSignIcon"
                            color="secondary"
                            :statistic="averageOrderPrice"
                            statistic-title="Average order price"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="DollarSignIcon"
                            color="secondary"
                            :statistic="totalSales"
                            statistic-title="Total sales"
                        />
                    </b-col>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="BoxIcon"
                            color="success"
                            :statistic="totalAmountOfOrders"
                            statistic-title="Total orders"
                        />
                    </b-col>
                </b-row>
            </div>
        </div>
        <div
            v-else
            class="row mx-auto justify-content-center align-items-center flex-column"
        >
            <b-spinner
                style="width: 2rem; height: 2rem; margin-top: 20px;"
                variant="primary"
            />
        </div>
    </div>
</template>

<script>
const chartColors = {
    primaryColorShade: '#836AF9',
    yellowColor: '#ffe800',
    successColorShade: '#28dac6',
    warningColorShade: '#ffe802',
    warningLightColor: '#FDAC34',
    infoColorShade: '#299AFF',
    greyColor: '#4F5D70',
    blueColor: '#2c9aff',
    blueLightColor: '#84D0FF',
    greyLightColor: '#EDF1F4',
    tooltipShadow: 'rgba(0, 0, 0, 0.25)',
    lineChartPrimary: '#666ee8',
    lineChartDanger: '#ff4961',
    labelColor: '#6e6b7b',
    grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

import {
    BCard,
    BCardBody,
    BCardHeader,
    BCardSubTitle,
    BCardTitle,
    BCardText,
    BAlert,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BSpinner,
} from 'bootstrap-vue'
import Axios from 'axios'
import { $themeColors } from '@themeConfig'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ChartjsLineChart from '@/components/Charts/ChartjsLineChart'
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardSubTitle,
        BCardTitle,
        BCardText,
        BAlert,
        BDropdown,
        BDropdownItem,
        BSpinner,
        BRow,
        BCol,
        // StatisticCardWithLineChart,
        StatisticCardHorizontal,
        ChartjsLineChart,
        VueApexCharts,
    },
    data() {
        return {
            loading: false,
            plugins: [
                {
                    beforeInit(chart) {
                        /* eslint-disable func-names, no-param-reassign */
                        chart.legend.afterFit = function () {
                            this.height += 20
                        }
                        /* eslint-enable */
                    },
                },
            ],
            tableData: {},
            dataFound: false,
            ordersAccepted: [],
            ordersShipped: [],
            dateValuesForAccepted: [],
            dateValuesForShipped: [],
            shipmentsByDate: [],
            possibleDates: [],
            mostCommonCarrier: '',
            mostCommonCountry: '',
            averageOrderPrice: '',
            inventorySync: [],
            totalAmountOfOrders: 0,
            totalSales: 0,
            salesLine: {
                series: [
                    {
                        name: 'Item count',
                        data: [],
                    },
                ],
                chartOptions: {
                    chart: {
                        toolbar: { show: false },
                        zoom: { enabled: false },
                        type: 'line',
                        dropShadow: {
                            enabled: true,
                            top: 18,
                            left: 2,
                            blur: 5,
                            opacity: 0.2,
                        },
                        offsetX: -10,
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 4,
                    },
                    grid: {
                        borderColor: '#ebe9f1',
                        padding: {
                            top: -20,
                            bottom: 5,
                            left: 20,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    colors: ['#df87f2'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            inverseColors: false,
                            gradientToColors: [$themeColors.primary],
                            shadeIntensity: 1,
                            type: 'horizontal',
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100, 100, 100],
                        },
                    },
                    markers: {
                        size: 0,
                        hover: {
                            size: 5,
                        },
                    },
                    xaxis: {
                        labels: {
                            offsetY: 5,
                            style: {
                                colors: '#b9b9c3',
                                fontSize: '0.857rem',
                            },
                        },
                        axisTicks: {
                            show: false,
                        },
                        categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
                            'September', 'October', 'November', 'December'],
                        axisBorder: {
                            show: false,
                        },
                        tickPlacement: 'on',
                    },
                    yaxis: {
                        tickAmount: 5,
                        labels: {
                            style: {
                                colors: '#b9b9c3',
                                fontSize: '0.857rem',
                            },
                            formatter(val) {
                                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
                            },
                        },
                    },
                    tooltip: {
                        x: { show: false },
                    },
                },
            },
            productOrdersRadialBar: {
                series: [],
                chartOptions: {
                    labels: ['Accepted', 'Shipped'],
                    plotOptions: {
                        radialBar: {
                            size: 150,
                            hollow: {
                                size: '20%',
                            },
                            track: {
                                strokeWidth: '100%',
                                margin: 15,
                            },
                            dataLabels: {
                                value: {
                                    fontSize: '1rem',
                                    colors: '#5e5873',
                                    fontWeight: '500',
                                    offsetY: 5,
                                },
                                total: {
                                    show: true,
                                    label: 'Total',
                                    fontSize: '1.286rem',
                                    colors: '#5e5873',
                                    fontWeight: '500',

                                    formatter() {
                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                        return '100%'
                                    },
                                },
                            },
                        },
                    },
                    colors: [$themeColors.info, $themeColors.success],
                    stroke: {
                        lineCap: 'round',
                    },
                    chart: {
                        height: 355,
                        dropShadow: {
                            enabled: true,
                            blur: 3,
                            left: 1,
                            top: 1,
                            opacity: 0.1,
                        },
                    },
                },
            }
        }
    },
    created() {
        this.setTableData()
    },
    methods: {
        setTableData() {
            const orderData = []
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}mirakl/get-dashboard-data`,
                method: 'POST',
                data: { }
            }).then(resp => {
                orderData.push(resp.data)

                if(!('order_data' in orderData[0]) && !('dispatched_order_data' in orderData[0])
                    || orderData[0]['order_data'].length <= 0 && orderData[0]['dispatched_order_data'].length <= 0) {
                    this.loading = false
                    this.dataFound = false
                    return
                }

                this.ordersShipped = orderData[0]['dispatched_order_data']
                this.ordersAccepted = orderData[0]['order_data']
                this.inventorySync = orderData[0]['inventory_data']
                this.mostCommonCarrier = orderData[0]['dispatched_order_data_additional']['most_common_carrier']
                this.mostCommonCountry = (new Intl.DisplayNames(['en'], {type: 'region'})).of(orderData[0]['dispatched_order_data_additional']['most_common_country'])
                this.averageOrderPrice = orderData[0]['dispatched_order_data_additional']['average_order_price']
                this.productOrdersRadialBar.series.push(orderData[0]['dispatched_order_data_additional']['ordered_percentage'])
                this.productOrdersRadialBar.series.push(orderData[0]['dispatched_order_data_additional']['dispatched_percentage'])
                this.totalAmountOfOrders = orderData[0]['dispatched_order_data_additional']['total_orders']
                this.totalSales = orderData[0]['dispatched_order_data_additional']['total_sales']

                this.ordersAccepted.forEach(value => {
                    const date = new Date(value.created_at)
                    const month = (date.getMonth() + 1).toString().padStart(2, '0')
                    if(this.possibleDates.indexOf(`${date.getFullYear()}-${month}`) === -1)
                        this.possibleDates.push(`${date.getFullYear()}-${month}`)
                })

                this.possibleDates.forEach(value => {
                    this.dateValuesForAccepted.push(this.ordersAccepted.filter(order => order.created_at.includes(value)).length)
                    this.dateValuesForShipped.push(this.ordersShipped.filter(order => order.created_at.includes(value)).length)
                })

                const rand = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

                this.inventorySync.forEach(value => {
                    this.salesLine.chartOptions.xaxis.categories.filter(
                        (month, index) => {
                            const date = new Date()
                            date.setFullYear(2021)
                            date.setMonth(index + 1)
                            if (date.getMonth() === (new Date(value.created_at)).getMonth()) {
                                rand[index + 1] += parseInt(value.action.items_amount)
                            }
                        }
                    )
                })

                this.salesLine.series[0].data = rand

                this.tableData = {
                    labels: this.possibleDates,
                    datasets: [
                        {
                            data: this.dateValuesForAccepted,
                            label: 'Accepted orders',
                            borderColor: chartColors.warningLightColor,
                            lineTension: 0.5,
                            pointStyle: 'circle',
                            backgroundColor: chartColors.warningLightColor,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 5,
                            pointHoverBorderWidth: 5,
                            pointBorderColor: 'transparent',
                            pointHoverBorderColor: $themeColors.white,
                            pointHoverBackgroundColor: chartColors.warningLightColor,
                            pointShadowOffsetX: 1,
                            pointShadowOffsetY: 1,
                            pointShadowBlur: 5,
                            pointShadowColor: chartColors.tooltipShadow,
                        },
                        {
                            data: this.dateValuesForShipped,
                            label: 'Shipped orders',
                            borderColor: chartColors.lineChartDanger,
                            lineTension: 0.5,
                            pointStyle: 'circle',
                            backgroundColor: chartColors.lineChartDanger,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 5,
                            pointHoverBorderWidth: 5,
                            pointBorderColor: 'transparent',
                            pointHoverBorderColor: $themeColors.white,
                            pointHoverBackgroundColor: chartColors.lineChartDanger,
                            pointShadowOffsetX: 1,
                            pointShadowOffsetY: 1,
                            pointShadowBlur: 5,
                            pointShadowColor: chartColors.tooltipShadow,
                        },
                    ]
                }
                this.loading = false
                this.dataFound = true
            }).catch(() => {
                this.loading = false
                this.dataFound = false
            })
        },
    },
}
</script>
